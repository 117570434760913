/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import { X } from 'react-feather';

const PasswordProtect = () => {
  const [password, setPassword] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <form onSubmit={onSubmit}>
      <div className={'flex flex-col justify-center items-center h-screen bg-white text-black'}>
        <Link to="/" className={'absolute top-8 left-8 w-12 h-12 rounded-full border-2 border-black p-1.5 transition duration-200 hover:bg-black hover:text-white'}><X size='32'/></Link>
        <div className={'items-left w-80'}>
          <h1 style={{fontFamily: 'Geomanist Medium'}} className={'text-7xl mb-2'}>shh. <span role='img' aria-label='hand wave emoji' className={'text-6xl'}>🤫</span></h1>
          <p className={'mb-6'}>Some of my projects are password protected. Please <Link className='display-link' to='/contact'>contact me</Link> if you'd like to see them.</p>
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            style={{fontFamily: 'Geomanist Light'}}
            className={'w-full h-12 rounded mb-4 p-2 tracking-wider bg-white border-2 border-black placeholder-black placeholder-opacity-60'}
          />
          <button
            type="submit"
            style={{fontFamily: 'Geomanist Book'}}
            className={'w-full h-12 bg-black hover:bg-gray-800 text-white rounded tracking-wider transition duration-200 transform'}
            >
            Enter
          </button>
        </div>
      </div>
    </form>
  );
};

export default PasswordProtect;
